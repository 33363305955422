export default {
  colors: {
    dark_blue: '#0E2356',
    light_grey: '#D6E3E4',
    white: '#FFFFFF',
  },
  fonts: {
    size: {
      small: '1.3rem',
      normal: '1.6rem',
      big: '2rem',
    },

    family: {
      light: 'Apercu-Light',
      medium: 'Apercu-Medium',
      bold: 'Apercu-Bold',
      standard: 'Apercu',
    },
  },
}
