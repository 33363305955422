import styled from 'styled-components'

export const StyledActivityForm = styled.div`
  &:nth-child(2n + 1) {
    background: ${({ theme }) => theme.colors.dark_blue};
    color: ${({ theme }) => theme.colors.white};
  }

  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }

  h1 {
    font-family: ${({ theme }) => theme.fonts.family.standard};
  }

  h2 {
    font-family: ${({ theme }) => theme.fonts.family.light};
  }

  p {
    font-family: ${({ theme }) => theme.fonts.family.standard};
  }

  form {
    display: inline-flex;
    flex-direction: column;
    text-align: justify;
    font-family: ${({ theme }) => theme.fonts.family.light};
    margin-top: 2rem;

    & > *:not(:last-child, h3) {
      margin-bottom: 3rem;
    }

    h3 {
      margin-top: 2rem;
      margin-bottom: 1rem;
      text-align: center;
    }
    h3.errors {
      color: red;
    }

    div {
      display: flex;
      align-items: center;

      input[type='radio'],
      input[type='number'] {
        border-radius: 1rem;
        border: 1px solid ${({ theme }) => theme.colors.dark_blue};
        margin-right: 1rem;
      }

      input[type='number'].errors {
        border: 2px solid red;
      }

      input[type='number'] {
        height: 3rem;
        width: 13rem;
        text-align: center;
      }

      input[type='radio'] {
        height: 2rem;
        width: 2rem;
      }
    }

    .radioInputs {
      flex-direction: column;
      align-items: flex-start;
      align-self: center;

      div {
        margin-top: 1rem;
      }
    }
  }
`
