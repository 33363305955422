import styled from 'styled-components'

export const StyledForm = styled.div`
  background: ${({ theme }) => theme.colors.dark_blue};
  min-height: 100vh;
  text-align: center;
  padding: 0.5rem;

  .main {
    position: relative;
    max-width: 100rem;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 1rem;
    margin: auto;
    margin-top: 7rem;
    padding: 4rem 0 4rem 0;
    @media (min-width: 1000px) {
      padding: 4rem 3rem 4rem 3rem;
    }
    & > div:not(.logo) {
      padding: 3rem 1rem;
    }

    .logo {
      position: absolute;
      top: -1rem;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20rem;
      padding: 2rem 4rem;
      border-radius: 1rem;
      color: white;
      background: ${({ theme }) => theme.colors.dark_blue};
      img {
        width: 100%;
      }
    }

    .intro, .validation {
      & > *:not(:last-child) {
        margin-bottom: 2rem;
      }

      .validation-subtext {
        p {
          margin-bottom: 3px;
        }
      }
    }

    .form-error {
      margin-top: 15px;
      color: red;
    }

    button {
      margin-top: 2rem;
      background: ${({ theme }) => theme.colors.white};
      padding: 1rem 6rem;
      border: 0.1rem solid ${({ theme }) => theme.colors.dark_blue};
      border-radius: 1rem;
      color: ${({ theme }) => theme.colors.dark_blue};
    }
  }
`
