import React from 'react'
import { StyledActivityForm } from './ActivityForm.styled'

const ActivityForm = ({
  activity: { id, title, role, month, purchase_order },
  index,
  updateActivitiesParams,
  formErrors
}) => {
  return (
    <StyledActivityForm>
      <h1>
        Activité {index} : {role}
      </h1>
      <p>PO : {purchase_order ? purchase_order : 'non précisé'}</p>
      <h2>{title}</h2>
      <form>
        <div>
          <input
            onChange={(event) => { updateActivitiesParams(id, 'quantity_expense', event.target.value) }}
            id="daysDone"
            type="number"
            onWheel={(e) => e.target.blur()}
            className={(formErrors[id] && formErrors[id]['quantity_expense']) ? 'errors' : ''} />
          <label htmlFor="daysDone">
            jours réalisés pour le mois de {month} <sup>*</sup>
          </label>
        </div>
        <div>
          <input onChange={(event) => { updateActivitiesParams(id, 'misc_expense', event.target.value) }}
            id="expenses"
            type="number"
            onWheel={(e) => e.target.blur()}
            className={(formErrors[id] && formErrors[id]['misc_expense']) ? 'errors' : ''} />
          <label htmlFor="expenses">
            € de notes de frais pour le mois de {month} <sup>*</sup>
          </label>
        </div>
        <h3 className={(formErrors[id] && formErrors[id]['happiness_indicator']) ? 'errors' : ''}>Comment t'es-tu senti(e) durant cette mission ce mois-ci ?</h3>
        <div className="radioInputs" onChange={(event) => updateActivitiesParams(id, 'happiness_indicator', event.target.value)}>
          <div>
            <input type="radio" id="top" name="thi" value="top" />
            <label htmlFor="top">Top</label>
          </div>
          <div>
            <input type="radio" id="good" name="thi" value="good" />
            <label htmlFor="good">Bien</label>
          </div>
          <div>
            <input type="radio" id="medium" name="thi" value="medium" />
            <label htmlFor="medium">Moyen</label>
          </div>
          <div>
            <input type="radio" id="bad" name="thi" value="bad" />
            <label htmlFor="bad">Mal</label>
          </div>
        </div>
      </form>
    </StyledActivityForm>
  )
}

export default ActivityForm
