import React, { useState, useEffect } from 'react'
import { StyledForm } from './Form.styled'
import { ThemeProvider } from 'styled-components'
import theme from '../../../assets/styles/theme.js'
import ActivityForm from '../ActivityForm/ActivityForm'
import Loader from "react-loader-spinner"

import logo from '../../../assets/images/logo_octo_acn_white.png'

const Form = () => {
  const [activities, setActivities] = useState([])
  const [activitiesParams, setActivitiesParams] = useState([])
  const [firstName, setFirstName] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [putSuccess, setPutSuccess] = useState(false)
  const [postError, setPostError] = useState(false)
  const [sendOnGoing, setSendOnGoing] = useState(false)

  useEffect(() => {
    fetchActivities()
  }, []);

  async function fetchActivities() {
    const response = await fetch(window.location.href + '.json')
    setLoaded(true)

    if (response.ok) {
      const json = await response.json()
      if (json.form_validated) {
        setPutSuccess(true)
      } else {
        updateActivitiesFromJson(json)
      }
    } else {
      setFetchError(true)
    }
  }

  const updateActivitiesFromJson = (json) => {
    const newActivities = json.data.map((data) => {
      return ({
        id: data.id,
        title: `${data.project.reference} - ${data.project.customer.name} - ${data.project.name}`,
        role: data.label,
        month: json.month,
        purchase_order: data.purchase_order
      })
    })

    let newActivitiesParams = {}
    newActivities.map((activity) => {
      newActivitiesParams[activity.id] = {
        project_expense_id: activity.id,
        quantity_expense: null,
        misc_expense: null,
        happiness_indicator: null
      }
    })

    setActivities(newActivities)
    setActivitiesParams(newActivitiesParams)
    setFirstName(json.first_name)
  }

  const updateActivitiesParams = (activityId, activityParam, activityParamNewValue) => {
    let newActivitiesParams = { ...activitiesParams }
    activitiesParams[activityId][activityParam] = activityParamNewValue
    setActivitiesParams(newActivitiesParams)
  }

  const sendForm = () => {
    if (sendOnGoing)
      return (0)

    let newFormErrors = {}
    Object.keys(activitiesParams).map((activityParams) => {
      let activityParamsError = {}
      if (activitiesParams[activityParams].quantity_expense === null)
        activityParamsError['quantity_expense'] = true

      if (activitiesParams[activityParams].misc_expense === null)
        activityParamsError['misc_expense'] = true

      if (activitiesParams[activityParams].happiness_indicator === null)
        activityParamsError['happiness_indicator'] = true

      if (Object.keys(activityParamsError).length > 0)
        newFormErrors[activityParams] = activityParamsError
    })

    setFormErrors(newFormErrors)
    if (Object.keys(newFormErrors).length === 0) {
      setSendOnGoing(true)
      postActivities()
    }
  }

  async function postActivities() {
    const activityParamsBody = Object.keys(activitiesParams).map((activityParams) => {
      return (activitiesParams[activityParams])
    })
    const csrfToken = document.getElementsByName('csrf-token')[0].getAttribute('content')
    const fetchInit = {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        'X-CSRF-TOKEN': csrfToken
      },
      body: JSON.stringify({
        form: {
          project_expenses: activityParamsBody
        }
      })
    }

    const response = await fetch(window.location.href + '.json', fetchInit)

    if (response.status === 200) {
      setPutSuccess(true)
    } else {
      const json = await response.json()
      const newFormErrors = {}
      json.forEach((element) => {
        newFormErrors[element.id] = element.error
      })
      setPostError(true)
    }

    setSendOnGoing(false)
  }

  const body = () => {
    if (putSuccess) {
      return (successBody())
    } else if (fetchError) {
      return (errorBody())
    } else {
      return (formBody())
    }
  }

  const successBody = () => {
    return (
      <>
        <div className="validation">
          <h1>Merci !</h1>

          <div className="validation-subtext">
            <p>Ton CRA nous a bien été envoyé, si ce n'est pas déjà fait,</p>
            <p>n'oublie pas de nous envoyer ta facture à</p>
            <p><a href='mailto:acn.france.inv@accenture.com?bcc=contact_freelances@octo.com'>acn.france.inv@accenture.com</a></p>
            <p>en mettant en copie contact_freelances@octo.com</p>
          </div>
        </div>
      </>
    )
  }

  const errorBody = () => {
    return (
      <>
        <div className="intro">
          <p>
            Une erreur est survenue.
          </p>
        </div>
      </>
    )
  }

  const formBody = () => {
    return (
      <>
        <div className="intro">
          <h1>Bonjour {firstName} !</h1>
          <p>
            Tu es inscrit sur {activities.length} activité{activities.length > 1 ? 's' : ''} ce mois-ci !
          </p>
          {activities.length > 0 && <p>
            Merci de nous indiquer le nombre de jours que tu as réalisé sur le mois entier et le montant de tes notes de frais HT du mois en cours.
          </p>}
        </div>
        {console.log(activities)}
        {activities.map((activity, index) => (
          <ActivityForm activity={activity} index={index + 1} key={index} updateActivitiesParams={updateActivitiesParams} formErrors={formErrors} />
        ))}
        {postError && <p className="form-error">Une erreur est survenue. Veuillez reessayer ou contacter le support</p>}
        {Object.keys(formErrors).length > 0 && <p className="form-error">Un champ obligatoire n'est pas rempli</p>}
        {activities.length > 0 && <button onClick={sendForm}>{sendOnGoing ? loader(55, 10) : 'Envoyer'}</button>}
      </>
    )
  }

  const loader = (width, height) => {
    return (
      <div className="loader-block">
        <Loader
          type="Hearts"
          color="#00BFFF"
          width={width}
          height={height}
        />
      </div>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledForm>
        <div className="main">
          <div className="logo">
            <img src={logo} alt="logo octo accenture white" />
          </div>
          {
            loaded ? body() : loader(200, 200)
          }
        </div>
      </StyledForm>
    </ThemeProvider>
  )
}

export default Form
